$(document).ready(function() {
  $(".pop-in .close").on('click', function() {
    // supprimer l'ancre de l'url sans recharger la page.
    $('#trigger')[0].click();
  });

  $('.pop-in').on('click', function(e) {
    if (e.target !== this)
      return;
    
    // supprimer l'ancre de l'url sans recharger la page.
    $('#trigger')[0].click();
  });
})